


































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { mapState } from 'vuex';
import OrganisationPaymentModal from '@/modules/organisation/components/Pricing.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { Watch } from 'vue-property-decorator';
import service from '../services/app-http-client';
import { appModule } from '../store/modules/app.module';
import SubscribeButton from '@/modules/marketing/pricing/SubscribeButton.vue';
import UpgradeButton from '@/modules/marketing/pricing/UpgradeButton.vue';
import userModule from '@/store/modules/user/user.module';

@Component({
  components: {
    OrganisationPaymentModal,
    SubscribeButton,
    UpgradeButton,
  },
  name: 'organisation',
  // layout: 'AppMain',
  metaInfo: {
    title: 'Dashboard',
  },
  computed: {
    ...mapState('tenant', ['tenant']),
  },
})
export default class Organisation extends Vue {
  isActive = false;
  hasPayment = this.$router.currentRoute.query.payment;
  trial = tenantModule.tenant.subscription.plan.name === 'Trial';
  userRole = userModule.member[0].role.role;

  async mounted() {
    if (tenantModule.tenant?.subscription?.isActive === false) {
      this.showPaymentModal = true;
    }
    if (Object.keys(tenantModule?.accommodations).length === 0) {
      await tenantModule.getAccommodations();
    }
    await appModule.findQuestions();

    if (this.hasPayment && this.hasPayment === '1') {
      this.$swal(
        'Payment successful',
        'Thank you, your payment has been processed and your account subscription is now active.',
        'success',
      );
    } else if (this.hasPayment && this.hasPayment === '0') {
      this.$swal('Payment cancelled', 'Your payment has been cancelled', 'error');
    }

    // const eventSource = new EventSource(`http://localhost:3000/v1/api/booking/stream/${tenantModule.apiKey}`, { withCredentials: true });

    // eventSource.onmessage = ({ data }) => {
    //   tenantModule.setMetrics(JSON.parse(data));
    //
    // };
  }

  get showPaymentModal() {
    return tenantModule.showPricing;
  }

  set showPaymentModal(show: boolean) {
    tenantModule.setShowPricing(show);
  }

  @Watch('$store.state.tenant.showPricing')
  handle(show: boolean) {
    this.showPaymentModal = show;
  }

  // get isActive(): boolean {
  //   return Notification.showError;
  // }

  // set isActive(value: boolean) {
  //   Notification.modalError({ isActive: value, errorMessage: '' });
  // }
}
